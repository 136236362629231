import React, { useEffect } from "react";
import "../styles/globals.css";
import initAuth from "../utils/initAuth";
import { AppProps } from "next/app";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { install } from "resize-observer";
export { reportWebVitals } from "next-axiom";
import NProgress from 'nprogress'; //nprogress module
import '../styles/nprogress.css'; //styles of nprogress
import { useRouter } from "next/router";

config.autoAddCss = false;

initAuth();
if (typeof window !== "undefined") {
  install();
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    NProgress.configure({ showSpinner: false, })

    router.events.on('routeChangeStart', () => NProgress.start());
    router.events.on('routeChangeComplete', () => NProgress.done());
    router.events.on('routeChangeError', () => NProgress.done());
  }, [router.events]);

  return <Component {...pageProps} />;
}

export default MyApp;
