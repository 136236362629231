import { log } from "next-axiom";
import { init } from "next-firebase-auth";
import absoluteUrl from "next-absolute-url";

const TWELVE_DAYS_IN_MS = 12 * 60 * 60 * 24 * 1000;

const REDIRECT_PARAM = "r";

const initAuth = () => {
  init({
    debug: false,

    // This demonstrates setting a dynamic destination URL when
    // redirecting from app pages. Alternatively, you can simply
    // specify `authPageURL: '/auth-ssr'`.
    // authPageURL: "/auth",  // Old setup in case this new redirect doesn't work
    authPageURL: ({ ctx }) => {
      const isServerSide = typeof window === "undefined";
      const origin = isServerSide
        ? absoluteUrl(ctx.req).origin
        : window.location.origin;
      const destPath =
        typeof window === "undefined" ? ctx.resolvedUrl : window.location.href;
      const destURL = new URL(destPath, origin);
      return `/auth?${REDIRECT_PARAM}=${encodeURIComponent(destURL)}`;
    },

    // This demonstrates setting a dynamic destination URL when
    // redirecting from auth pages. Alternatively, you can simply
    // specify `appPageURL: '/'`.
    // appPageURL: "/loginRedirect", // Old in case the new redirects don't work
    appPageURL: ({ ctx }) => {
      const isServerSide = typeof window === "undefined";
      const origin = isServerSide
        ? absoluteUrl(ctx.req).origin
        : window.location.origin;
      const params = isServerSide
        ? new URL(ctx.req.url, origin).searchParams
        : new URLSearchParams(window.location.search);
      const destinationParamVal = params.get(REDIRECT_PARAM)
        ? decodeURIComponent(params.get(REDIRECT_PARAM))
        : undefined;

      // By default, go to the index page if the destination URL
      // is invalid or unspecified.
      let destURL = "/loginRedirect";
      if (destinationParamVal) {
        // Verify the redirect URL host is allowed.
        // https://owasp.org/www-project-web-security-testing-guide/v41/4-Web_Application_Security_Testing/11-Client_Side_Testing/04-Testing_for_Client_Side_URL_Redirect
        const allowedHosts = ["localhost:3000", "indoorascents.com"];
        const allowed =
          allowedHosts.indexOf(new URL(destinationParamVal).host) > -1;
        if (allowed) {
          destURL = destinationParamVal;
        } else {
          log.warn(
            `Redirect destination host must be one of ${allowedHosts.join(
              ", "
            )}.`
          );
        }
      }
      return destURL;
    },
    loginAPIEndpoint: "/api/login",
    logoutAPIEndpoint: "/api/logout",
    firebaseAdminInitConfig: {
      credential: {
        projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
        clientEmail: process.env.FIREBASE_CLIENT_EMAIL,
        privateKey: process.env.FIREBASE_PRIVATE_KEY
          ? JSON.parse(process.env.FIREBASE_PRIVATE_KEY)
          : undefined,
      },
      databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    },
    firebaseClientInitConfig: {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    },
    cookies: {
      name: "IndoorAscents",
      keys: [
        process.env.COOKIE_SECRET_CURRENT,
        process.env.COOKIE_SECRET_PREVIOUS,
      ],
      httpOnly: true,
      maxAge: TWELVE_DAYS_IN_MS,
      overwrite: true,
      path: "/",
      sameSite: "strict",
      secure: process.env.NEXT_PUBLIC_COOKIE_SECURE === "true",
      signed: true,
    },
  });
};

export default initAuth;
